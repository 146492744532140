import React from "react";

import SvgIcon from "@mui/material/SvgIcon";

export default function ArenzaHeadLogo(props) {
  return (
    <SvgIcon
      sx={{
        height: "34px",
        width: "35px",
      }}
      viewBox="0 0 35 34"
      {...props}
    >
      <path d="M34.7603 29.3868C34.732 29.213 34.6753 29.0879 34.6753 29.0879C34.4768 28.5621 34.0229 26.9489 33.9095 25.2918C33.8244 24.2029 33.9094 20.8369 33.9945 18.657C34.0513 18.0696 34.0796 17.4742 34.0796 16.8711C34.0796 12.3209 32.3213 8.06686 29.0882 4.89183C25.8834 1.737 21.6293 0 17.0915 0C13.83 0 10.6819 0.920835 7.95928 2.66296C7.02337 3.26662 6.7397 4.52372 7.33528 5.47102C7.93086 6.41804 9.17885 6.69682 10.1431 6.09288C12.2135 4.76751 14.5958 4.06684 17.0915 4.06684C20.5516 4.06684 23.813 5.38994 26.2521 7.79176C28.6911 10.1956 30.024 13.4204 30.024 16.8711C30.024 24.0313 24.2101 29.8562 17.0915 29.8562C12.1 29.8562 7.5907 26.8926 5.37855 22.595C7.13692 23.2584 8.92344 23.6134 10.6818 23.6134C14.4822 23.6134 18.2258 22.1538 21.1753 19.5033C22.0262 18.7551 22.0831 17.4693 21.3457 16.6318C20.6083 15.794 19.3035 15.7215 18.4811 16.4694C16.2689 18.4539 13.4895 19.5466 10.6818 19.5466C8.21442 19.5466 5.52024 18.5401 3.27973 16.7849C2.62743 16.2805 1.74813 16.2128 1.03911 16.6125C0.330084 17.013 -0.0952184 17.8033 0.0182252 18.6175V18.6419C0.472 22.7751 2.48557 26.6203 5.63363 29.4688C8.81005 32.3411 12.8941 33.9231 17.0915 33.9231C20.8919 33.9231 24.4086 32.6594 27.2447 30.5287C28.1239 29.9632 29.0882 29.2989 29.0882 29.2989C29.5419 29.8935 30.875 32.1426 32.3214 32.2177C32.4632 32.2316 32.6333 32.2361 32.6333 32.2361C33.8528 32.2361 34.8454 31.2382 34.8454 30.007C34.8454 29.7917 34.8171 29.5834 34.7603 29.3868ZM9.09371 11.2223C9.09371 12.4537 8.10114 13.4514 6.88162 13.4514C5.63374 13.4514 4.641 12.4537 4.641 11.2223C4.641 9.99076 5.63374 8.99311 6.88162 8.99311C8.10114 8.99311 9.09371 9.99076 9.09371 11.2223ZM17.7155 11.2223C17.7155 12.4537 16.7227 13.4514 15.5032 13.4514C14.2553 13.4514 13.2628 12.4537 13.2628 11.2223C13.2628 9.99076 14.2553 8.99311 15.5032 8.99311C16.7227 8.99311 17.7155 9.99076 17.7155 11.2223Z" fill="#FF780F"/>
    </SvgIcon>
  );
}
