import PropTypes from "prop-types";
import React from "react";

import { Box, Card as CardUI, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Card({ href, image, imageMinHeight, slug, title }) {
  return (
    <a
      className="swiper-slide-link"
      href={`${process.env.GATSBY_ROOT_URL}/${href}/${slug}`}
      key={slug}
      rel="noreferrer noreferrer"
    >
      <CardUI
        sx={{
          width: "100%",
          height: "100%",
          transition: "0.3s",
          borderRadius: "16px",
          border: "1px solid #E7E8EA",
          overflow: "hidden",
          "&:hover": {
            boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
            border: "1px solid #fff",
          },
          transform: "translateZ(0)", // HACK: https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
        }}
      >
        <Box
          sx={{
            padding: '24px 24px 0 24px',
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: imageMinHeight || 'max-content'
          }}
        >
          <GatsbyImage alt="" image={image.localFile.childImageSharp.gatsbyImageData} />
        </Box>
        <Box
          sx={{
            padding: '16px 24px 24px 24px',
            display: "flex",
            flexDirection: "column",
            flex: 1,
            minHeight: '100px',
            maxHeight: { xs: '100px', md: 'max-content' },
          }}
        >
          <Typography
            component="div"
            sx={{
              textAlign: "left",
              overflow: { xs: 'hidden' },
              '-webkit-line-clamp': { xs: '3', md: '4' },
              '-webkit-box-orient': { xs: 'vertical' },
              display: { xs: '-webkit-box' }
            }}
            variant="h4"
          >
            {title}
          </Typography>
        </Box>
      </CardUI>
    </a>
  );
}

Card.propTypes = {
  href: PropTypes.string,
  image: PropTypes.object,
  slug: PropTypes.string,
  title: PropTypes.string,
  imageMinHeight: PropTypes.string
};
