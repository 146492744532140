import React, { useState, useContext, useEffect } from "react";

import { AzHeader, AzLogo, AzMainMenu, AzSecondLineMenu, AzLoginButton } from "@Development/ui-kit";
import {
    Dialog,
    IconButton,
    Box,
    SvgIcon
} from "@mui/material";
import { ApplicationContext } from '@product-site-frontend/shared';
import ConsultationForm from '@product-site-frontend/shared/components/ConsultationForm';

import { NAV_LINKS, NAV_LINKS_EXTENDED_LINE } from "../constants/nav-links";
import * as styles from './appBar.module.css';
import popupImage from "../assets/popup.png";

export default function AppBar() {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const { setApplicationOpen } = useContext(ApplicationContext);

  const [showModalCallback, setShowModalCallback] = useState(false);
  const [activeSMenuLink, setActiveSMenuLink] = useState(null);

  function handleApplicationButtonClick() {
    setApplicationOpen(true);
  }

  const toggleShowModalCallback = () => {
    setShowModalCallback(!showModalCallback);
  }

  useEffect(() => {
    let newActiveLink = null;

    NAV_LINKS_EXTENDED_LINE({
      gatsby: process.env.GATSBY_ROOT_URL,
      shop: process.env.GATSBY_SHOP_URL,
      documents: process.env.GATSBY_DOCUMENTS_PUBLIC_URL
    }).forEach((element, id) => {
      if (url === element.link || url === `${element.link}/`) {
        newActiveLink = id;
      }
    });

    setActiveSMenuLink(newActiveLink);
  }, [url]);

  return (
    <AzHeader
      button={(<AzLoginButton
        urls={[
          {
            url: `${process.env.GATSBY_ACCOUNT_URL}/login`,
            text: 'Для клиентов'
          },
          {
            url: `${process.env.GATSBY_ACCOUNT_URL}/partnership/login`,
            text: 'Для партнеров'
          },
          {
            url: `${process.env.GATSBY_AGENT_URL}/login`,
            text: 'Для агентов'
          }
        ]}
      />)}
      className={styles.header}
      fixedWidth={true}
      handleApplicationCallBackClick={toggleShowModalCallback}
      href="/"
      logo={(<AzLogo svg={{ width: 125, height: 36 }} type="basic-new" />)}
      secondLine={(
        <AzSecondLineMenu
          className={styles[`secondLineActive${activeSMenuLink}`]}
          menu={
            NAV_LINKS_EXTENDED_LINE({
              gatsby: process.env.GATSBY_ROOT_URL,
              shop: process.env.GATSBY_SHOP_URL,
              documents: process.env.GATSBY_DOCUMENTS_PUBLIC_URL
            })
          }
        />
      )}
    >
      <AzMainMenu
        button={(<AzLoginButton
          small={true}
          urls={[
            {
              url: `${process.env.GATSBY_ACCOUNT_URL}/login`,
              text: 'Для клиентов'
            },
            {
              url: `${process.env.GATSBY_ACCOUNT_URL}/partnership/login`,
              text: 'Для партнеров'
            },
            {
              url: `${process.env.GATSBY_AGENT_URL}/login`,
              text: 'Для агентов'
            }
          ]}
        />)}
        className={styles[`mobileLineActive${activeSMenuLink}`]}
        handleApplicationButtonClick={handleApplicationButtonClick}
        handleApplicationCallBackClick={toggleShowModalCallback}
        href="/"
        logo={(<AzLogo svg={{ width: 125, height: 36 }} type="basic-new" />)}
        menu={
          NAV_LINKS({
            gatsby: process.env.GATSBY_ROOT_URL,
            shop: process.env.GATSBY_SHOP_URL
          })
        }
        mobileMenu={
          NAV_LINKS_EXTENDED_LINE({
            gatsby: process.env.GATSBY_ROOT_URL,
            shop: process.env.GATSBY_SHOP_URL,
            documents: process.env.GATSBY_DOCUMENTS_PUBLIC_URL
          })
        }
      />
      {showModalCallback && (
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            onClose={toggleShowModalCallback}
            open={true}
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: '920px',
                    borderRadius: {
                        md: '24px',
                        xs: '6px'
                    }
                }
            }}
        >
            <Box sx={{
                display: 'grid',
                gridTemplateColumns: {
                    xs: '1fr',
                    lg: '440px 1fr'
                }
            }}>
                <Box
                    alt="Картинка формы заказать звонок"
                    component="img"
                    src={popupImage}
                    sx={{
                        display: { xs: "none", lg: "block" },
                        width: 440,
                    }}
                />
                <Box sx={{
                    boxSizing: 'border-box',
                    padding: '40px 48px 25px',

                    '& form div + div': {
                        marginTop: 0
                    },

                    '& form div + div + div': {
                        marginTop: 0,
                        marginBottom: '24px'
                    },

                    '& form span > a': {
                        color: '#f60',
                        textDecoration: 'underline'
                    }
                }}>
                    <Box sx={{
                        fontSize: '30px',
                        fontWeight: 800,
                        lineHeight: '32px',
                        letterSpacing: '0.02em',
                        marginBottom: '16px'
                    }}>
                        Заказать звонок
                    </Box>

                    <ConsultationForm />
                </Box>
            </Box>
            <IconButton aria-label="close" onClick={toggleShowModalCallback} sx={{
                position: 'absolute',
                top: '24px',
                right: '24px'
            }}>
                <SvgIcon>
                    <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#B6BBC2" fillRule="evenodd" />
                </SvgIcon>
            </IconButton>
        </Dialog>
      )}
    </AzHeader>
  );
}
