import React, { useCallback, useState, useEffect, useRef } from 'react';

import { Box, Typography, Dialog, IconButton, SvgIcon, Button } from '@mui/material';

import popupImage from '../../assets/bannerCar.png';
import popupImageMobile from '../../assets/bannerCarMobile.png';

export default function BannerAuto() {
    const [dialogShowBefore, setDialogShowBefore] = useState((typeof window !== 'undefined' && !!window?.localStorage?.getItem('leasingCarModalShown')) || null);
    const [showDialog, setShowDialog] = useState(false);

    const timeoutId = useRef(null);

    const handleDialogOpen = useCallback(() => {
        if (!dialogShowBefore) {
            setShowDialog(true);
            setDialogShowBefore(true);
            window?.localStorage.setItem('leasingCarModalShown', 'true');
        }
    }, [dialogShowBefore]);

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    useEffect(() => {
        if (!dialogShowBefore) {
            timeoutId.current = setTimeout(handleDialogOpen, 10000);
        }

        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (showDialog) {
        return (
            <Dialog
                fullWidth={true}
                maxWidth="lg"
                onClose={handleDialogClose}
                open={true}
                sx={{
                    '& .MuiDialog-paper': {
                        maxWidth: '897px',
                        borderRadius: {
                            md: '24px',
                            xs: '6px'
                        },
                        backgroundImage: {
                            xs: `url("${popupImageMobile}")`,
                            md: `url("${popupImage}")`
                        },
                        backgroundPosition: {
                            xs: 'top center',
                            md: 'center',
                            lg: 'center'
                        },
                        backgroundSize: {
                            xs: 'cover',
                            lg: 'contain'
                        },
                        backgroundRepeat: 'no-repeat'
                    }
                }}
            >
                <Box sx={{
                    display: 'block',
                    minHeight: {
                        xs: '500px',
                        md: '423px'
                    }
                }}>
                    <Box sx={{
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}>
                        <SvgIcon fill='none' sx={{ width: '257px', height: 'auto', display: { xs: 'none', md: 'inline' } }} viewBox='0 0 514 135' xmlns="http://www.w3.org/2000/svg">
                            <path d="M513.089 0L456.565 109.553C448.502 125.182 432.388 135 414.802 135L0 135L5.90104e-06 3.82226e-06L513.089 0Z" fill="#0D1D32" />
                            <path d="M214.531 73.9239C214.531 72.4392 214.94 71.1515 215.758 70.0607C216.576 68.97 217.652 68.1367 218.985 67.5611C220.349 66.9854 221.758 66.5763 223.212 66.3339C224.666 66.0613 226.181 65.9249 227.757 65.9249H228.439V65.5159C228.439 63.7888 227.333 62.9253 225.121 62.9253C223.06 62.9253 220.909 63.6676 218.667 65.1523L215.94 59.6076C217.001 58.7895 218.455 58.0775 220.303 57.4715C222.182 56.8655 224.03 56.5625 225.848 56.5625C229.09 56.5625 231.635 57.4109 233.483 59.1076C235.362 60.8044 236.301 63.1374 236.301 66.1067L236.301 80.5139L229.12 80.5139V79.105L229.211 77.6507H229.12C228.363 78.7111 227.348 79.5443 226.075 80.1503C224.833 80.7563 223.53 81.0593 222.167 81.0593C219.924 81.0593 218.091 80.3776 216.667 79.0141C215.243 77.6507 214.531 75.9539 214.531 73.9239ZM222.303 73.1967C222.303 73.7724 222.5 74.2572 222.894 74.651C223.288 75.0146 223.833 75.1964 224.53 75.1964C225.621 75.1964 226.56 74.7571 227.348 73.8784C228.136 72.9997 228.529 72.0605 228.529 71.0606V70.4243H227.802C226.348 70.4243 225.06 70.6516 223.939 71.1061C222.848 71.5302 222.303 72.2271 222.303 73.1967Z" fill="white" />
                            <path d="M192.758 77.6052L195.894 72.6968C196.651 73.3936 197.545 73.9845 198.575 74.4692C199.636 74.9237 200.62 75.151 201.529 75.151C203.408 75.151 204.347 74.4692 204.347 73.1058C204.347 72.4695 204.165 71.9847 203.802 71.6514C203.469 71.3181 202.802 71.1515 201.802 71.1515H198.939V65.9704H201.302C202.878 65.9704 203.665 65.3644 203.665 64.1524C203.665 62.9102 202.847 62.289 201.211 62.289C199.514 62.289 197.666 62.8799 195.667 64.0615L193.394 59.1985C195.939 57.4412 199.09 56.5625 202.847 56.5625C205.332 56.5625 207.392 57.1533 209.028 58.335C210.665 59.5167 211.483 61.0922 211.483 63.0617C211.483 65.5159 210.513 67.2732 208.574 68.3337V68.4246C210.998 69.5456 212.21 71.2727 212.21 73.6057C212.21 75.9994 211.316 77.8476 209.528 79.1505C207.741 80.423 205.408 81.0593 202.529 81.0593C200.742 81.0593 198.969 80.7715 197.212 80.1958C195.485 79.5898 194 78.7263 192.758 77.6052Z" fill="white" />
                            <path d="M166.5 80.5154L166.5 57.1094L174.363 57.1094V65.381L181.589 65.381V57.1094L189.452 57.1094L189.452 80.5154L181.589 80.5154V71.9711L174.363 71.9711V80.5154L166.5 80.5154Z" fill="white" />
                            <path d="M139.414 68.7882C139.414 65.2735 140.52 62.3648 142.732 60.062C144.944 57.729 147.898 56.5625 151.594 56.5625C154.957 56.5625 157.593 57.623 159.502 59.7439C161.441 61.8345 162.411 64.5463 162.411 67.8792C162.411 68.4246 162.35 69.2427 162.229 70.3334L147.458 70.3334C147.731 71.6969 148.367 72.7422 149.367 73.4694C150.397 74.1663 151.579 74.5147 152.912 74.5147C154.851 74.5147 157.003 73.7269 159.366 72.1514L162.275 77.6507C161.032 78.7111 159.502 79.5443 157.684 80.1503C155.897 80.7563 154.094 81.0593 152.276 81.0593C148.307 81.0593 145.171 79.8473 142.868 77.4234C140.565 74.9995 139.414 72.1211 139.414 68.7882ZM147.64 65.834L154.639 65.834C154.639 64.8341 154.336 64.0009 153.73 63.3343C153.155 62.6678 152.382 62.3345 151.412 62.3345C149.413 62.3345 148.155 63.501 147.64 65.834Z" fill="white" />
                            <path d="M111.937 89.6036L111.938 57.1079L119.027 57.1079V58.2895L118.937 59.7894H119.027C120.633 57.6381 122.997 56.5625 126.117 56.5625C129.299 56.5625 131.844 57.6987 133.753 59.9711C135.692 62.2133 136.662 65.1674 136.662 68.8336C136.662 72.4998 135.631 75.454 133.571 77.6961C131.511 79.9382 128.905 81.0593 125.754 81.0593C123.512 81.0593 121.527 80.317 119.8 78.8323H119.709C119.77 79.3777 119.8 80.0443 119.8 80.8321L119.8 89.6036L111.937 89.6036ZM119.573 68.97C119.573 70.4849 119.967 71.8029 120.755 72.924C121.573 74.0148 122.709 74.5601 124.163 74.5601C125.466 74.5601 126.557 74.0602 127.435 73.0603C128.314 72.0605 128.753 70.697 128.753 68.97C128.753 67.2429 128.329 65.8492 127.481 64.7887C126.633 63.7282 125.527 63.198 124.163 63.198C122.86 63.198 121.77 63.6828 120.891 64.6524C120.012 65.6219 119.573 67.0611 119.573 68.97Z" fill="white" />
                            <path d="M79 80.5114L90.044 48.0156L98.4066 48.0156L109.451 80.5114L101.27 80.5114L99.2701 73.6941L89.135 73.6941L87.1807 80.5114L79 80.5114ZM90.9984 67.3313H97.4067L95.5887 61.0139L94.2707 55.4237H94.1798C93.695 57.7871 93.2557 59.6505 92.8618 61.0139L90.9984 67.3313Z" fill="white" />
                            <path d="M344.966 77.6961C342.421 75.4237 341.148 72.4847 341.148 68.8791C341.148 65.2735 342.421 62.3193 344.966 60.0166C347.511 57.7139 350.647 56.5625 354.374 56.5625C358.131 56.5625 361.282 57.7139 363.827 60.0166C366.403 62.289 367.69 65.2432 367.69 68.8791C367.69 72.4847 366.418 75.4237 363.873 77.6961C361.328 79.9382 358.177 81.0593 354.419 81.0593C350.662 81.0593 347.511 79.9382 344.966 77.6961ZM349.102 68.8791C349.102 70.5758 349.617 71.9393 350.647 72.9694C351.677 73.9996 352.935 74.5147 354.419 74.5147C355.904 74.5147 357.161 73.9996 358.192 72.9694C359.222 71.9393 359.737 70.5758 359.737 68.8791C359.737 67.152 359.222 65.7583 358.192 64.6978C357.161 63.6373 355.904 63.1071 354.419 63.1071C352.935 63.1071 351.677 63.6373 350.647 64.6978C349.617 65.7583 349.102 67.152 349.102 68.8791Z" fill="#FF780F" />
                            <path d="M324.442 80.5154L324.442 63.6994L317.352 63.6994V57.1094L339.44 57.1094V63.6994L332.304 63.6994L332.304 80.5154L324.442 80.5154Z" fill="#FF780F" />
                            <path d="M293.719 80.5154L293.719 57.1094L306.49 57.1094C308.914 57.1094 310.838 57.6396 312.262 58.7001C313.716 59.7605 314.443 61.2755 314.443 63.2449C314.443 64.2751 314.201 65.2598 313.716 66.1991C313.231 67.1081 312.565 67.8201 311.716 68.3352V68.3806C312.959 68.7745 313.883 69.4562 314.489 70.4258C315.095 71.3651 315.398 72.4104 315.398 73.5618C315.398 75.9554 314.549 77.7127 312.853 78.8338C311.186 79.9549 309.065 80.5154 306.49 80.5154L293.719 80.5154ZM301.581 66.3354H304.536C305.232 66.3354 305.747 66.1234 306.081 65.6992C306.444 65.2447 306.626 64.6841 306.626 64.0176C306.626 63.4116 306.444 62.9268 306.081 62.5632C305.717 62.1693 305.172 61.9724 304.445 61.9724L301.581 61.9724V66.3354ZM301.581 75.607H305.035C305.823 75.607 306.429 75.3797 306.853 74.9252C307.308 74.4707 307.535 73.9254 307.535 73.2891C307.535 72.6225 307.323 72.062 306.899 71.6075C306.475 71.153 305.869 70.9258 305.081 70.9258H301.581L301.581 75.607Z" fill="#FF780F" />
                            <path d="M260.781 80.5114L271.825 48.0156L280.188 48.0156L291.232 80.5114L283.051 80.5114L281.051 73.6941L270.916 73.6941L268.962 80.5114L260.781 80.5114ZM272.78 67.3313H279.188L277.37 61.0139L276.052 55.4237H275.961C275.476 57.7871 275.037 59.6505 274.643 61.0139L272.78 67.3313Z" fill="#FF780F" />
                            <circle cx="249.587" cy="74.8295" fill="white" r="5.68107" />
                            <path d="M376.141 62.636C376.141 59.6225 378.583 57.1797 381.597 57.1797L420.38 57.1797C423.393 57.1797 425.836 59.6226 425.836 62.636V75.5773C425.836 78.5908 423.393 81.0336 420.38 81.0336L381.597 81.0336C378.583 81.0336 376.141 78.5908 376.141 75.5773L376.141 62.636Z" fill="url(#paint0_linear_2304_1375)" />
                            <path d="M406.952 75.3117L403.992 63.1484L407.122 63.1484L408.653 70.7526C408.699 70.9794 408.733 71.2005 408.755 71.416C408.789 71.6202 408.806 71.7733 408.806 71.8753V72.0284H408.84C408.852 71.6769 408.92 71.2516 409.045 70.7526L410.916 63.1484H413.314L415.135 70.7526C415.191 70.9794 415.237 71.2005 415.271 71.416C415.305 71.6202 415.328 71.7733 415.339 71.8753V72.0284H415.373C415.373 71.6769 415.424 71.2516 415.526 70.7526L417.193 63.1484L420.272 63.1484L417.193 75.3117L413.876 75.3117L412.345 69.1705L412.107 67.7415H412.073C412.039 68.1725 411.959 68.6488 411.834 69.1705L410.303 75.3117L406.952 75.3117Z" fill="white" />
                            <path d="M395.117 75.3117V63.1484L402.738 63.1484V65.7002L398.094 65.7002V67.9117L401.803 67.9117V70.4634L398.094 70.4634V72.7599L402.976 72.7599V75.3117L395.117 75.3117Z" fill="white" />
                            <path d="M382.117 75.3117V63.1484L385.128 63.1484L388.496 68.8303C388.633 69.0684 388.786 69.3633 388.956 69.7149C389.126 70.0551 389.262 70.3386 389.364 70.5654L389.517 70.9057H389.551C389.46 70.0324 389.415 69.3406 389.415 68.8303V63.1484H392.392V75.3117H389.398L386.013 69.6468C385.877 69.4087 385.724 69.1195 385.554 68.7792C385.383 68.4277 385.247 68.1385 385.145 67.9117L384.992 67.5714H384.958C385.049 68.4447 385.094 69.1365 385.094 69.6468V75.3117H382.117Z" fill="white" />
                            <defs>
                                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2304_1375" x1="425.836" x2="376.974" y1="81.0336" y2="74.6524">
                                    <stop stopColor="#FF910F" />
                                    <stop offset="1" stopColor="#FF780F" />
                                </linearGradient>
                            </defs>
                        </SvgIcon>
                        <SvgIcon fill='none' sx={{ width: '43px', height: 'auto', display: { xs: 'inline', md: 'none' } }} viewBox='0 0 43 146' xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.38186e-06 146L34.8948 127.823C39.8726 125.23 43 120.048 43 114.393L43 0L1.21747e-06 3.77724e-06L6.38186e-06 146Z" fill="#0D1D32" />
                            <path d="M26.878 74.125C26.4989 74.125 26.1701 74.0206 25.8916 73.8117C25.6131 73.6028 25.4004 73.3282 25.2534 72.9878C25.1064 72.6397 25.002 72.28 24.9401 71.9087C24.8705 71.5374 24.8357 71.1506 24.8357 70.7483L24.8357 70.5742L24.7313 70.5742C24.2903 70.5742 24.0698 70.8566 24.0698 71.4213C24.0698 71.9474 24.2594 72.4966 24.6384 73.0691L23.2228 73.7653C23.0139 73.4945 22.8321 73.1232 22.6774 72.6513C22.5227 72.1717 22.4453 71.6998 22.4453 71.2357C22.4453 70.4079 22.6619 69.7581 23.0951 69.2862C23.5283 68.8066 24.124 68.5668 24.8821 68.5668L28.5605 68.5668L28.5605 70.4002L28.2008 70.4002L27.8295 70.377L27.8295 70.4002C28.1002 70.5936 28.313 70.8527 28.4677 71.1776C28.6224 71.4948 28.6997 71.8275 28.6997 72.1756C28.6997 72.748 28.5257 73.216 28.1776 73.5796C27.8295 73.9432 27.3963 74.125 26.878 74.125ZM26.6923 72.1408C26.8393 72.1408 26.963 72.0905 27.0636 71.9899C27.1564 71.8893 27.2029 71.7501 27.2029 71.5722C27.2029 71.2937 27.0907 71.0539 26.8663 70.8527C26.642 70.6516 26.4022 70.551 26.1469 70.551L25.9845 70.551L25.9845 70.7367C25.9845 71.108 26.0425 71.4368 26.1585 71.723C26.2668 72.0015 26.4447 72.1408 26.6923 72.1408Z" fill="white" />
                            <path d="M27.8179 79.6797L26.5647 78.879C26.7426 78.6856 26.8934 78.4574 27.0172 78.1944C27.1332 77.9236 27.1913 77.6722 27.1913 77.4402C27.1913 76.9605 27.0172 76.7207 26.6691 76.7207C26.5066 76.7207 26.3829 76.7671 26.2978 76.86C26.2127 76.9451 26.1701 77.1153 26.1701 77.3705L26.1701 78.1016L24.8473 78.1016L24.8473 77.4982C24.8473 77.0959 24.6926 76.8948 24.3831 76.8948C24.066 76.8948 23.9074 77.1037 23.9074 77.5214C23.9074 77.9546 24.0582 78.4265 24.3599 78.937L23.1183 79.5172C22.6697 78.8674 22.4453 78.0629 22.4453 77.1037C22.4453 76.4693 22.5962 75.9433 22.8979 75.5255C23.1996 75.1078 23.6018 74.8989 24.1047 74.8989C24.7313 74.8989 25.1799 75.1465 25.4507 75.6416L25.4739 75.6416C25.7601 75.0227 26.2011 74.7133 26.7967 74.7133C27.4079 74.7133 27.8797 74.9415 28.2124 75.3979C28.5373 75.8543 28.6997 76.45 28.6997 77.1849C28.6997 77.6413 28.6263 78.0938 28.4793 78.5425C28.3246 78.9835 28.1041 79.3625 27.8179 79.6797Z" fill="white" />
                            <path d="M28.5619 86.3828L22.5859 86.3828L22.5859 84.3754L24.6978 84.3754L24.6978 82.5304L22.5859 82.5304L22.5859 80.5229L28.5619 80.5229L28.5619 82.5304L26.3804 82.5304L26.3804 84.3754L28.5619 84.3754L28.5619 86.3828Z" fill="white" />
                            <path d="M25.5667 93.2969C24.6694 93.2969 23.9267 93.0145 23.3388 92.4498C22.7431 91.8851 22.4453 91.1308 22.4453 90.1871C22.4453 89.3284 22.7161 88.6554 23.2576 88.168C23.7913 87.6729 24.4837 87.4254 25.3347 87.4254C25.4739 87.4254 25.6828 87.4408 25.9613 87.4718L25.9613 91.243C26.3094 91.1734 26.5763 91.0109 26.7619 90.7556C26.9398 90.4926 27.0288 90.1909 27.0288 89.8506C27.0288 89.3555 26.8277 88.8062 26.4254 88.2028L27.8295 87.4602C28.1002 87.7773 28.313 88.168 28.4677 88.6322C28.6224 89.0886 28.6997 89.5489 28.6997 90.013C28.6997 91.0264 28.3903 91.8271 27.7714 92.415C27.1526 93.0029 26.4177 93.2969 25.5667 93.2969ZM24.8125 91.1966L24.8125 89.4096C24.5572 89.4096 24.3445 89.487 24.1743 89.6417C24.0041 89.7887 23.919 89.9859 23.919 90.2335C23.919 90.744 24.2168 91.0651 24.8125 91.1966Z" fill="white" />
                            <path d="M30.8813 100.32L22.5846 100.32L22.5846 98.5101L22.8863 98.5101L23.2692 98.5333L23.2692 98.5101C22.7199 98.1001 22.4453 97.4967 22.4453 96.6999C22.4453 95.8877 22.7354 95.2379 23.3156 94.7505C23.888 94.2554 24.6423 94.0079 25.5783 94.0079C26.5144 94.0079 27.2686 94.2709 27.8411 94.7969C28.4135 95.323 28.6997 95.9882 28.6997 96.7928C28.6997 97.3652 28.5102 97.8719 28.1312 98.3129L28.1312 98.3361C28.2704 98.3206 28.4406 98.3129 28.6417 98.3129L30.8813 98.3129L30.8813 100.32ZM25.6131 98.3709C25.9999 98.3709 26.3364 98.2703 26.6227 98.0692C26.9012 97.8603 27.0404 97.5702 27.0404 97.1989C27.0404 96.8663 26.9128 96.5878 26.6575 96.3634C26.4022 96.1391 26.0541 96.0269 25.6131 96.0269C25.1722 96.0269 24.8164 96.1352 24.5456 96.3518C24.2748 96.5684 24.1395 96.8508 24.1395 97.1989C24.1395 97.5315 24.2632 97.81 24.5108 98.0344C24.7583 98.2587 25.1258 98.3709 25.6131 98.3709Z" fill="white" />
                            <path d="M28.5623 108.727L20.2656 105.907L20.2656 103.772L28.5623 100.952L28.5623 103.041L26.8218 103.551L26.8218 106.139L28.5623 106.638L28.5623 108.727ZM25.1972 105.663L25.1972 104.027L23.5843 104.491L22.157 104.828L22.157 104.851C22.7604 104.975 23.2362 105.087 23.5843 105.187L25.1972 105.663Z" fill="white" />
                            <path d="M27.8411 40.8222C27.2609 41.472 26.5105 41.7969 25.5899 41.7969C24.6694 41.7969 23.9151 41.472 23.3272 40.8222C22.7393 40.1723 22.4453 39.3717 22.4453 38.4202C22.4453 37.4609 22.7393 36.6564 23.3272 36.0066C23.9074 35.349 24.6616 35.0203 25.5899 35.0203C26.5105 35.0203 27.2609 35.3452 27.8411 35.995C28.4135 36.6448 28.6997 37.4493 28.6997 38.4086C28.6997 39.3678 28.4135 40.1723 27.8411 40.8222ZM25.5899 39.7662C26.0231 39.7662 26.3713 39.6347 26.6343 39.3717C26.8973 39.1087 27.0288 38.7876 27.0288 38.4086C27.0288 38.0295 26.8973 37.7085 26.6343 37.4455C26.3713 37.1824 26.0231 37.0509 25.5899 37.0509C25.149 37.0509 24.7931 37.1824 24.5224 37.4455C24.2516 37.7085 24.1163 38.0295 24.1163 38.4086C24.1163 38.7876 24.2516 39.1087 24.5224 39.3717C24.7931 39.6347 25.149 39.7662 25.5899 39.7662Z" fill="#FF780F" />
                            <path d="M28.5619 46.0726L24.2685 46.0726L24.2685 47.8828L22.5859 47.8828L22.5859 42.2434L24.2685 42.2434L24.2685 44.0652L28.5619 44.0652L28.5619 46.0726Z" fill="#FF780F" />
                            <path d="M28.5619 53.9141L22.5859 53.9141L22.5859 50.6534C22.5859 50.0345 22.7213 49.5433 22.9921 49.1797C23.2628 48.8084 23.6496 48.6227 24.1524 48.6227C24.4155 48.6227 24.6669 48.6846 24.9067 48.8084C25.1388 48.9322 25.3206 49.1024 25.4521 49.319L25.4637 49.319C25.5642 49.0018 25.7383 48.7659 25.9858 48.6111C26.2257 48.4564 26.4925 48.3791 26.7865 48.3791C27.3976 48.3791 27.8463 48.5957 28.1325 49.0289C28.4188 49.4543 28.5619 49.9959 28.5619 50.6534L28.5619 53.9141ZM24.9415 51.9066L24.9415 51.1524C24.9415 50.9744 24.8874 50.8429 24.7791 50.7578C24.663 50.665 24.5199 50.6186 24.3497 50.6186C24.195 50.6186 24.0712 50.665 23.9784 50.7578C23.8778 50.8507 23.8275 50.9899 23.8275 51.1756L23.8275 51.9066L24.9415 51.9066ZM27.3087 51.9066L27.3087 51.0247C27.3087 50.8236 27.2507 50.6689 27.1346 50.5606C27.0186 50.4445 26.8793 50.3865 26.7169 50.3865C26.5467 50.3865 26.4036 50.4407 26.2875 50.549C26.1715 50.6573 26.1135 50.812 26.1135 51.0131L26.1135 51.9066L27.3087 51.9066Z" fill="#FF780F" />
                            <path d="M28.5623 62.3203L20.2656 59.5006L20.2656 57.3655L28.5623 54.5458L28.5623 56.6345L26.8218 57.145L26.8218 59.7327L28.5623 60.2316L28.5623 62.3203ZM25.1972 59.2569L25.1972 57.6208L23.5843 58.0849L22.157 58.4214L22.157 58.4447C22.7604 58.5684 23.2362 58.6806 23.5843 58.7812L25.1972 59.2569Z" fill="#FF780F" />
                            <circle cx="27.1145" cy="65.1745" fill="white" r="1.45047" transform="rotate(-90 27.1145 65.1745)" />
                            <path d="M23.9946 32.8672C23.2253 32.8672 22.6016 32.2435 22.6016 31.4741L22.6016 21.5721C22.6016 20.8028 23.2253 20.1791 23.9946 20.1791L27.2988 20.1791C28.0682 20.1791 28.6919 20.8028 28.6919 21.5721L28.6919 31.4741C28.6919 32.2435 28.0682 32.8672 27.2988 32.8672L23.9946 32.8672Z" fill="url(#paint0_linear_2304_1398)" />
                            <path d="M27.2305 25.0021L24.125 25.7578L24.125 24.9586L26.0665 24.5677C26.1244 24.5562 26.1808 24.5475 26.2359 24.5417C26.288 24.533 26.3271 24.5287 26.3531 24.5287L26.3922 24.5287L26.3922 24.52C26.3024 24.5171 26.1939 24.4997 26.0665 24.4678L24.125 23.9901L24.125 23.3777L26.0665 22.9129C26.1244 22.8985 26.1808 22.8869 26.2359 22.8782C26.288 22.8695 26.3271 22.8637 26.3531 22.8608L26.3922 22.8608L26.3922 22.8521C26.3024 22.8521 26.1939 22.8391 26.0665 22.813L24.125 22.3874L24.125 21.6013L27.2305 22.3874L27.2305 23.2343L25.6625 23.6252L25.2977 23.6861L25.2977 23.6947C25.4077 23.7034 25.5293 23.7237 25.6625 23.7555L27.2305 24.1464L27.2305 25.0021Z" fill="white" />
                            <path d="M27.2305 28.0234L24.125 28.0234L24.125 26.0776L24.7765 26.0776L24.7765 27.2634L25.3411 27.2634L25.3411 26.3165L25.9926 26.3165L25.9926 27.2634L26.579 27.2634L26.579 26.0168L27.2305 26.0168L27.2305 28.0234Z" fill="white" />
                            <path d="M27.2305 31.3437L24.125 31.3438L24.125 30.575L25.5757 29.715C25.6365 29.6803 25.7118 29.6412 25.8015 29.5977C25.8884 29.5543 25.9608 29.5196 26.0187 29.4935L26.1056 29.4544L26.1056 29.4457C25.8826 29.4689 25.706 29.4805 25.5757 29.4805L24.125 29.4805L24.125 28.7204L27.2305 28.7204L27.2305 29.4848L25.7841 30.3491C25.7233 30.3839 25.6495 30.423 25.5626 30.4664C25.4729 30.5098 25.399 30.5446 25.3411 30.5706L25.2543 30.6097L25.2543 30.6184C25.4772 30.5953 25.6538 30.5837 25.7841 30.5837L27.2305 30.5837L27.2305 31.3437Z" fill="white" />
                            <defs>
                                <linearGradient gradientUnits="userSpaceOnUse" id="paint0_linear_2304_1398" x1="28.6919" x2="27.0626" y1="20.1791" y2="32.6544">
                                    <stop stopColor="#FF910F" />
                                    <stop offset="1" stopColor="#FF780F" />
                                </linearGradient>
                            </defs>
                        </SvgIcon>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        bottom: {
                            xs: '90px',
                            md: '40px'
                        },
                        left: {
                            xs: '20px',
                            md: '40px'
                        },
                        gap: {
                            xs: '4px',
                            lg: '10px'
                        },
                        maxWidth: {
                            xs: 'calc(100% - 40px)',
                            md: 'calc(100% - 270px)',
                            lg: '39%'
                        }
                    }}>
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '22px',
                                    lg: '32px'
                                },
                                lineHeight: {
                                    xs: '22px',
                                    lg: '33px'
                                },
                                fontWeight: 500,
                                color: '#fff'
                            }}
                        >
                            Аренза запускает новое направление:
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: {
                                    xs: '35px',
                                    lg: '55px'
                                },
                                lineHeight: {
                                    xs: '35px',
                                    lg: '56px'
                                },
                                fontWeight: 900,
                                color: '#fff'
                            }}
                        >
                            Лизинг авто для бизнеса!
                        </Typography>
                    </Box>
                    <Button
                        color="inherit"
                        fullWidth={false}
                        href={`${process.env.GATSBY_ROOT_URL}/lizing-auto`}
                        size="large"
                        style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            borderRadius: '22px',
                            fontSize: '16px',
                            textTransform: 'none'
                        }}
                        sx={{
                            bottom: {
                                xs: '22px',
                                md: '40px'
                            },
                            left: {
                                xs: '20px',
                                md: 'auto'
                            },
                            right: {
                                xs: 'auto',
                                md: '40px'
                            },
                        }}
                        variant="contained"
                    >
                        Узнать больше
                        <SvgIcon height="11" sx={{ width: '6.5px !important', height: '11px !important', marginLeft: '10px' }} viewBox="0 0 13 22" width="6.5">
                            <path clipRule="evenodd" d="M0.405823 0.89207C0.962285 0.31253 1.88253 0.294461 2.46125 0.85171L13 10.9995L2.46125 21.1472C1.88253 21.7045 0.962285 21.6864 0.405823 21.1069C-0.150639 20.5273 -0.132595 19.6058 0.446126 19.0485L8.80531 10.9995L0.446126 2.95041C-0.132595 2.39316 -0.150639 1.47161 0.405823 0.89207Z" fill="#FF780F" fillRule="evenodd" />
                        </SvgIcon>
                    </Button>
                </Box>
                <IconButton aria-label="close" onClick={handleDialogClose} sx={{
                    position: 'absolute',
                    top: '14px',
                    right: '14px',
                    background: 'rgba(13, 29, 50, 0.24)',
                    borderRadius: '50%'
                }}>
                    <SvgIcon>
                        <path clipRule="evenodd" d="M5.70735 4.29288C5.31683 3.90235 4.68366 3.90235 4.29314 4.29288C3.90261 4.6834 3.90261 5.31657 4.29314 5.70709L10.5878 12.0017L4.29333 18.2961C3.9028 18.6867 3.9028 19.3198 4.29333 19.7104C4.68385 20.1009 5.31702 20.1009 5.70754 19.7104L12.002 13.4159L18.2964 19.7104C18.6869 20.1009 19.3201 20.1009 19.7106 19.7104C20.1011 19.3198 20.1011 18.6867 19.7106 18.2961L13.4162 12.0017L19.7108 5.70709C20.1013 5.31657 20.1013 4.6834 19.7108 4.29288C19.3203 3.90235 18.6871 3.90235 18.2966 4.29288L12.002 10.5875L5.70735 4.29288Z" fill="#fff" fillRule="evenodd" />
                    </SvgIcon>
                </IconButton>
            </Dialog>
        );
    }

    return '';
}